<template>
  <v-container dense class="no-select">
    <v-row v-if="gettingLocation === true">
      <v-col cols="12">
        <v-card class="background-blue-dark-tr">
          <v-card-text
            class="text-center white--text font-weight-bold"
          >To access the platform please enable your location</v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="errorStr === 'success'">
      <v-col cols="12" md="6" align-self="base-line" class="my-auto">
        <v-card class="background-blue-tr" >
          <v-card-text class="white--text text-center">
            <div class="mx-auto">
              <v-row align="center" class="mt-4" justify="center">
                <vue-typed-js
                  :strings="['legents, the ultimate nft experience for football club battle']"
                  :cursorChar="' '"
                  :typeSpeed="63"
                  @onComplete="startNext()"
                >
                  <h2 class="typing "></h2>
                </vue-typed-js>
              </v-row>
            </div>

            <br />
            <br />
            <v-img height="248" contain :src="ff"></v-img>
            <br />
            <h2 class="warning--text">“even legends die, but legents lives 4ever… #legents4ever</h2>

            <br />
            <v-btn
              v-if="is_signed_in === true"
              dense
              color="white"
              block
              class="text-lowercase"
              @click="accessContract"
            >access contract</v-btn>

            <v-btn
              v-else
              dense
              @click="contract_dialog = true"
              color="blue"
              block
              class="text-lowercase white--text"
            >sign contract to enter legents</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card color="blue">
          <v-card-text  style=" text-align: justify;
                margin: 0 auto;
  text-justify: inter-word;" class="white--text font-weight-bold">
            <h1 class="text-right typewriter" style="font-size: 90px; position: absolute; right:0; top: -21px; ">.</h1>

            <p>Behind every game, behind each training and behind each goal there is passion, dedication and commitment to the beauty of the game. As seamless the game may seem, it has been built on more than 200 years of sweat & tears.</p>
            <p>Yet behind this unique manifestation of this game are people like us that pushed it forward. They were no ordinary men & women they where the best of them all. Yet through the misery and glory of time few made to the top and few yet remained.</p>
            <p>But as any great players, Maradona & the like, legends fucking DIE. Yet as technology is growing and evolution jumped into a different dimension, we want to turn everyone of all our legends to immortal players passing, scoring and building the ever growing digital world.</p>
            <p>For us is an act to honor the greatest of the game for future generation to not just see them as card but to meet them as the legends they are.</p>
            <p>Hence a new world for the best is born to allow the wining & fame grow beyond the feet of the players to the heart and souls of the FANS the foundation of our beautiful game. Hence players will compete again in a world where they will always live in 4ever.</p>
            <p>For that some call them legends but we call them legents…
             <br>the next generation of football history…</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-dialog v-model="contract_dialog" centered max-width="450">
          <SignContract></SignContract>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SignContract from '@/components/Contracts/SignContract'
import ff from '@/assets/Vector-Stadium-PNG-Clipart.png'
import store from '@/store'
export default {
  name: 'Home',

  components: {
    SignContract
  },
  data() {
    return {
      ff: ff,
      contract_dialog: false,
      location: {},
      start_second: false,
      start_story: 1,
      gettingLocation: true,
      errorStr: 's',
      story_line_1: ['<p>Behind every game, behind each training and behind each goal there is passion, dedication and commitment to the beauty of the game. <brAs seamless the game may seem, it has been built on more than 200 years of sweat & tears.</p> <p>Yet behind this unique manifestation of this game are people like us that pushed it forward. </p> <p>They were no ordinary men & women they where the best of them all. Yet through the misery and glory of time few made to the top and few yet remained.</p>', ''],
      story_line_2: ['<p>Yet behind this unique manifestation of this game are people like us that pushed it forward. </p>', ''],
      story_line_3: ['<p>They were no ordinary men & women they where the best of them all. Yet through the misery and glory of time few made to the top and few yet remained.</p>', ''],
      story_line_4: ['<p>But as any great players, Maradona & the like, legends fucking DIE. Yet as technology is growing and evolution jumped into a different dimension, we want to turn everyone of all our legends to immortal players passing, scoring and building the ever growing digital world. </p>'],
      story_line_5: ['<p>For us is an act to honor the greatest of the game for future generation to not just see them as card but to meet them as the legends they are. </p>'],
      story_line_6: ['<p>Hence a new world for the best is born to allow the wining & fame grow beyond the feet of the players to the heart and souls of the FANS the foundation of our beautiful game. Hence players will compete again in a world where they will always live in 4ever. </p>'],
      story_line_7: ['<p> For that some call them legends but we call them legents… the next generation of football history… </p>']

    }
  },
  methods: {
    accessContract() {
      this.$router.push({ name: 'BusinessMaterial' })
    },
    startNext() {
      this.start_story = this.start_story + 1
    },
    startSecond() {
      console.log("hi")
      this.start_second = true;
    },
    getLocation() {
      navigator.geolocation.getCurrentPosition(pos => {
        this.gettingLocation = false;
        this.location = pos;
        this.errorStr = 'success';
        store.dispatch('reports/saveLL', pos)


      }, err => {
        this.gettingLocation = false;
        this.errorStr = err.message;
      })
    }
  },
  mounted() {
    this.getLocation();
    store.dispatch('reports/getIpClient')
  },
  computed: {
    inRange() {
      const coords = this.$geolocation.coords

      return coords
    },
    is_signed_in() {
      return store.state.user_persist.access.access
    }
  }
}
</script>
<style>
.typewriter {
  animation: blink-caret 0.75s step-end infinite;
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    color: transparent;
  }
  50% {
    color: white;
  }
}
</style>