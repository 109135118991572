<template>
    <v-card>
        <v-card-text class="text-center">
            <br />
            <h3>access blueprints contract</h3>
        </v-card-text>
        <v-card-text v-if="account_status === 'initial'">
            <v-text-field label="username" outlined dense v-model="form.user_full_name"></v-text-field>

            <v-text-field
                label="email"
                type="email"
                outlined
                dense
                v-model="form.user_email_address"
            ></v-text-field>
            <v-select label="type" dense v-model="form.user_type" outlined :items="items"></v-select>

            <v-text-field label="phone number" outlined dense v-model="form.user_phone_number"></v-text-field>
            <v-text-field label="company name" outlined dense v-model="form.user_company_name"></v-text-field>
           
            <v-btn
          
                block
                dense
                color="blue"
                @click="signContract"
                class="white--text text-lowercase"
            >
                <v-icon>mdi-fountain-pen-tip</v-icon>sign contract
            </v-btn>
        </v-card-text>
        <v-card-text v-if="account_status === 'registered'">
            <h5
                class="text-center"
            >To recover your account keep this phrase safe. The only method to access your account will be using this phase so it is important not to loose this phrase.</h5>
            <v-card class="pa-3">
                <v-card-text class="font-weight-bold text-center blue--text">{{ account_recovery_phrase }}</v-card-text>
            </v-card>
            <br />
            <br />
            
            <h4>verify the contract with the code recieved in your email</h4>
           
            <v-otp-input v-model="form_1.verification_code"   @finish="onFinish"></v-otp-input>
            <v-btn block dense color="blue" class="white--text text-lowercase" @click="verify_code">
                <v-icon>mdi-fountain-pen</v-icon>verify signature
            </v-btn>
        </v-card-text>
    </v-card>
</template>
<script>
import store from '@/store'
export default {
    name: 'SignContract',
    data() {
        return {
            dialog_terms: false,
            items: [
                'startup', 'developer', 'graphic designer', '3d-artist', 'nft developer', 'game developer', 'manager', 'crypto developer'
            ],
            companies: ['modlab.gg', 'ardkon.com', 'modular cx', 'other'],
            is_signed: false,
            form: {
                user_company_name: '',
                user_full_name: '',
                user_email_address: '',
                user_type: '',
                user_phone_number: ''

            },
            e6: 0,
            steps_info:[{
                step_name: 'This Non-Disclosure and Confidentiality Agreement',
                step_id: 0,
                step_description_1: 'The Parties are interested in exploring a potential business opportunity (the “Opportunity”).  In order to adequately evaluate whether the Parties would like to pursue the Opportunity, it is necessary for both Parties to exchange certain confidential information.',
                step_description_2: '',
                step_description_3: '',
                step_statment: ''
            },{
                step_name: 'Confidential Information',
                step_id: 1,
                step_description_1: 'The confidential information (“Confidential Information”) includes any information that is only known by the disclosing Party, and not known by the general public at the time it is disclosed, whether tangible or intangible, and through whatever means it is disclosed.',
                step_description_2: 'Confidential Information does not include information that:',
                step_description_3: 'The receiving Party lawfully gained before the disclosing Party actually disclosed it; <br><br> Is disclosed to the receiving Party by a third party who is not bound by a confidentiality agreement;<br><br> Becomes available to the general public by no fault of the receiving Party; or<br><br>Is required by law to be disclosed.',
                step_statment: ''
            },{
                step_name: 'Use of Confidential Information',
                step_id: 2,
                step_description_1: 'During the course of this Agreement, the Parties will have access to and learn of each others’ Confidential Information, including trade secrets, industry knowledge, and other confidential information. The Parties will not share any of this proprietary information at any time. The Parties also will not use any of this proprietary information for either Party’s personal/business benefit at any time. This section remains in full force and effect even after termination of the Parties’ relationship by its natural termination or early termination by either Party.',
                step_description_2: 'The receiving Party may disclose the Confidential Information to its personnel on an as-needed basis.  The personnel must be informed that the Confidential Information is confidential and the personnel must agree to be bound by the terms of this Agreement.  The receiving Party is liable for any breach of this Agreement by their personnel. ',
                step_description_3: 'In the event a Party loses Confidential Information or inadvertently discloses Confidential Information, that Party must notify the other Party within twenty-four (24) hours. That Party must also take any and all steps necessary to recover the Confidential Information and prevent further unauthorized use.<br><br>In the event a Party is required by law to disclose Confidential Information, that Party must notify the other Party of the legal requirement to disclose within three (3) business days of learning of the requirement. ',
                step_statment: 'Notices must be made in accordance with Section 9 of this Agreement.'
            },{
                step_name: 'Ownership and Title',
                step_id: 3,
                step_description_1: 'Nothing in this Agreement will convey a right, title, interest, or license in the Confidential Information to the receiving party.  The Confidential Information will remain the exclusive property of the disclosing party.',
                step_description_2: ' ',
                step_description_3: '',
                step_statment: ''
            },{
                step_name: 'Return of Confidential Information',
                step_id: 4,
                step_description_1: 'Upon termination of this Agreement, the receiving party must return all tangible materials it has that contain the Confidential Information it received, including all electronic and hard copies.  This includes, but is not limited to, any notes, memos, drawings, summaries, excerpts and anything else derived from the Confidential Information.',
                step_description_2: ' ',
                step_description_3: '',
                step_statment: ''
            },{
                step_name: 'Remedies',
                step_id: 5,
                step_description_1: 'The Parties agree the Confidential Information is unique in nature and money damages will not adequately remedy the irreparable injury breach of this Agreement may cause the injured Party.  The injured Party is entitled to seek injunctive relief, as well as any other remedies that are available in law and equity.',
                step_description_2: ' ',
                step_description_3: '',
                step_statment: ''
            },{
                step_name: 'Relationship of the Parties. ',
                step_id: 6,
                step_description_1: 'No Binding Agreement to Pursue Opportunity.',
                step_description_2: ' The Parties agree they are exploring a potential Opportunity and sharing their Confidential Information is not a legal obligation to pursue the Opportunity.  Either Party is free to terminate discussions or negotiations related to the Opportunity at any time.',
                step_description_3: '<strong>No Exclusivity</strong><br>The Parties understand this Agreement is not an exclusive arrangement.  The Parties agree they are free to enter into other similar agreements with other parties.<br><br><strong>Independent Contractors</strong> <br> The Parties to this Agreement are independent contractors.  Neither Party is an agent, representative, partner, or employee of the other Party. ',
                step_statment: ''
            },{
                step_name: 'General',
                step_id: 7,
                step_description_1: 'Assignment. The Parties may not assign their rights and/or obligations under this Agreement.',
                step_description_2: ' Complete Contract. This Agreement constitutes the Parties entire understanding of their rights and obligations. This Agreement supersedes any other written or verbal communications between the Parties.  Any subsequent changes to this Agreement must be made in writing and signed by both Parties. ',
                step_description_3: '',
                step_statment: ''
            },{
                step_name: 'Notice',
                step_id: 8,
                step_description_1: 'Notices. All notices under this Agreement must be sent by email with return receipt requested or certified or registered mail with return receipt requested.',
                step_description_2: '',
                step_description_3: '',
                step_statment: ''
            }],
            info_terms: {
                intro: 'This Non-Disclosure and Confidentiality Agreement (the “Agreement”) is entered into __________________ (the “Effective Date”) by and between ____________________ (“[NAME]”) located at _________________________________ and ____________________ (“[NAME]”) located at ___________________________, also individually referred to as the “Party”, and collectively the “Parties”.',
                intro_1: 'The Parties are interested in exploring a potential business opportunity (the “Opportunity”).  In order to adequately evaluate whether the Parties would like to pursue the Opportunity, it is necessary for both Parties to exchange certain confidential information.',
                step_1: {
                    intro: 'Confidential Information. The confidential information (“Confidential Information”) includes any information that is only known by the disclosing Party, and not known by the general public at the time it is disclosed, whether tangible or intangible, and through whatever means it is disclosed.',
                    section_1: {
                        title: 'Confidential Information does not include information that:',
                        steps: ['The receiving Party lawfully gained before the disclosing Party actually disclosed it;', 'Is disclosed to the receiving Party by a third party who is not bound by a confidentiality agreement;', 'Becomes available to the general public by no fault of the receiving Party; or', 'Is required by law to be disclosed.']
                    },
                    section_2: {
                        title: 'Use of Confidential Information.',
                        steps: ['During the course of this Agreement, the Parties will have access to and learn of each others’ Confidential Information, including trade secrets, industry knowledge, and other confidential information. The Parties will not share any of this proprietary information at any time. The Parties also will not use any of this proprietary information for either Party’s personal/business benefit at any time. This section remains in full force and effect even after termination of the Parties’ relationship by its natural termination or early termination by either Party.', 'The receiving Party may disclose the Confidential Information to its personnel on an as-needed basis.  The personnel must be informed that the Confidential Information is confidential and the personnel must agree to be bound by the terms of this Agreement.  The receiving Party is liable for any breach of this Agreement by their personnel. ', 'Becomes available to the general public by no fault of the receiving Party; or', 'Is required by law to be disclosed.']
                    },

                }

            },
            form_1: {
                verification_code:'',

            }

        }
    },
    methods: {
        signContract() {
            store.dispatch('reports/createUser', this.form)
        },
        verify_code(){
            store.dispatch('reports/verifySignature', this.form_1)
        },
        onFinish(otp){
            this.form_1.verification_code = otp
        }

    },
    computed: {
        account_status() {
            return store.state.reports.account_status
        },
        account_recovery_phrase() {
            return store.state.reports.registration_info.hedera_account.mnemonic_string
        },
       
    }
}
</script>