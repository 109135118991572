<template>
 <v-app class="appp bg-blue">
    <v-app-bar color="transparent" dense>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-main class="main">
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>

<style>
@import './styles_modified.css';

::-webkit-scrollbar {
    width: 3px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: darkgrey;
  }
  
  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  }
.appp{
  background-image:  url("./assets/moon11.png")   !important;
    height: 100vh;
    background-color: #0b102d !important;
    background-repeat: no-repeat!important;

    overflow: hidden;
}

.main{

    height: 98vh;
    overflow-y: scroll;
}</style>
