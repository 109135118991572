
/*
function createGenerativeNumber() {
  return Math.floor(100000000 + Math.random() * 900000000)
}
*/

import router from "@/router"


const actions = {


  agree({ commit }) {
    commit('AGREE' )``
  }, storeUserInfo({ commit }, user_info) {
    commit('SAVE_USER_INFO' ,user_info)``
  },
  storeAccess({ commit }, access) {


    console.log(access.data.app_info.access)
    commit('SAVE_ACCESS' ,access)
    if(access.data.app_info.access === true){
      router.push({name: 'BusinessMaterial'})
    }
  }

}

const user_perisit = {
  namespaced: true,
  state: () => ({
    user_info:{},
    access: {},
    terms: false

   
    
  }),
  mutations: {
   SAVE_USER_INFO(state, user_info){
     state.user_info = user_info
   } , AGREE(state){
     state.terms = true
   } ,
   SAVE_ACCESS(state, access){
     state.access = access.data.app_info
   }
  },
  getters: {


    
  },
  actions
}

export default user_perisit