import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
          customProperties: true,
        },
        dark: false,
      themes: {
       
        light: {
          primary: "#282C33",
          secondary: "#e2e5e8",
          accent: "#82B1FF",
          error: "#cb152b",
          info: "#369ad4",
          success: "#4CAf50",
          warning: "#FFC107",
          danger:'#991517',
          grey: "#808488",
          background:'#ffffff',
          blue:'#203f6f',
          bluee:'#203f5e',

          blue_transparent: "rgba(0,0,0,0.2)"
  
        },
        dark: {
          primary: "#282C33",
          secondary: "#e4e4e4",
          accent: "#82B1FF",
          error: "#FF5252",
          info: "#132F3E",
          blue: "#203f6f",
          btrans: "#204d4d",
          success: "#00ab66",
          warning: "#FFC107",
          danger:'#991517',
          background:'#282C33'
  
        }
      },
    },
});
