import axios from 'axios'
const application_client_id = '44fa11c8-d0f0-42ec-88d4-8d92e3037bea';
const application_client_secret = 'f5bdb0ef-5ecf-4f4d-b3db-70ab87dc616b';
import store from '@/store'
/*
function createGenerativeNumber() {
  return Math.floor(100000000 + Math.random() * 900000000)
}
*/
var user_ip_address = '';
import { NFTStorage } from 'nft.storage'

const apiKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweEQ1OTY0MUY4NEIxQjlENDA1MDE1ODk3NTgwNWY1QTA2RWRDOTg5QzkiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTYzODEyNjk2Njc2NiwibmFtZSI6ImFwaTEifQ.XyXHLooicbv-PyZ0yME7lEFUVuGpKYUTnSAUyyoxrb8'
const client = new NFTStorage({ token: apiKey })


const actions = {
  async createNFT({ commit }, file) {
    console.log(file)

    const data = 'Hello nft.storage!'
    const cid = await client.storeBlob(new Blob([data]))
    console.log(cid)
    commit('Save')

  },
  async getIpClient() {
    try {
      const response = await axios.get('https://api.ipify.org?format=json');
      user_ip_address = response.data.ip
      console.log(user_ip_address)
      axios.post('https://apiss.ardkon.com/SaveIpLLVisitor?mvp=legents', { user_ip_address: user_ip_address, application_client_id: application_client_id, application_client_secret: application_client_secret }).then((response) => {
        console.log(response.data)
      }).catch((error) => {
        console.log(error);
      });
    } catch (error) {
      console.error(error);
    }
  },
  saveLL({commit}, location){
    console.log(location.coords)
    var location_set = {}
    location_set.user_longitude = location.coords.longitude
    location_set.user_latitude = location.coords.latitude
    commit('SET_LOCATION', location_set)
  },
  createUser({commit, state}, form){
    form.user_ip_address =  user_ip_address
    form.application_client_id = application_client_id
    form.application_client_secret = application_client_secret
    form.user_latitude = state.location.user_latitude
    form.user_longitude = state.location.user_longitude

    axios.post('https://apiss.ardkon.com/CreateAccountManual?mvp=legents', form).then((response) => {
      console.log(response.data)
      commit('REGISTERED',response.data )
      store.dispatch('user_persist/storeUserInfo',response.data.data.app_info)
    }).catch((error) => {
      commit('REGISTERED_ERROR')

      console.log(error);
    });
  }
,
verifySignature({ rootState}, form){
    form.application_client_id = application_client_id
    form.application_client_secret = application_client_secret

    console.log(form)
    form.user_id = rootState.user_persist.user_info.user_info.user_id
   
    axios.post('https://apiss.ardkon.com/VerifyAccount?mvp=legents', form).then((response) => {
      console.log(response.data)
      store.dispatch('user_persist/storeAccess', response.data)
    }).catch((error) => {

      console.log(error);
    });
  },
  
async  getUsers({commit}, form){
    form.application_client_id = application_client_id
    form.application_client_secret = application_client_secret

   
    axios.post('https://apiss.ardkon.com/GetUsers?mvp=legents', form).then((response) => {
      console.log(response.data)
    commit('SAVE_USERS', response.data.data.app_info)

    }).catch((error) => {

      console.log(error);
    });
  }
, async getMessages({commit}, form){
    form.application_client_id = application_client_id
    form.application_client_secret = application_client_secret

   
    axios.post('https://apiss.ardkon.com/GetTopicMessages?mvp=legents', form).then((response) => {
      console.log(response.data)
    commit('SAVE_MESSAGES', response.data.data.app_info)

    }).catch((error) => {

      console.log(error);
    });
  }
,
  nextSection({ commit }, step) {
    commit('NEXT_SECTION' ,step)
  }

}

const reports = {
  namespaced: true,
  state: () => ({
    location: {},
    account_status: 'initial',
    registration_info: {},
    step_1: [
      {
        title: 'name of your initiative',
        field_type: 'text-field',
        input_type: 'text',

        label: 'initiative name',
        items: [],
        value: '',
        input_id: 'initiative_name'

      }, {
        title: 'contact person',
        field_type: 'text-field',
        label: 'person name',
        items: [],
        input_type: 'text',

        value: '',
        input_id: 'person_contact'

      }, {
        title: 'contact email',
        field_type: 'text-field',
        label: 'email',
        input_type: 'email',

        items: [],
        value: '',
        input_id: 'contact_email'

      }, {
        title: 'name of the action',
        field_type: 'text-field',
        input_type: 'text',

        label: 'action_name',
        items: [],
        value: '',
        input_id: 'action_name'

      }, {
        title: 'link to your action page',
        field_type: 'text-field',
        label: 'link',
        input_type: 'text',

        items: [],
        value: '',
        input_id: 'action_link'

      }, {
        title: 'When did you launch your action?',
        field_type: 'text-field',
        label: 'date',
        input_type: 'date',
        items: [],
        value: '',
        input_id: 'launch_date'

      }, {
        title: 'Is this the first action that you launched on ardkon platform?',
        field_type: 'select-input',
        label: 'action',
        input_type: 'text',
        items: ['yes', 'no, we already did this one:'],
        value: '',
        input_value_condition: 'no, we already did this one:',
        value_2: '',
        label_2: 'action page link',
        input_id: 'first_action'

      }, {
        title: 'We are filling out this survey because: ',
        field_type: 'select',
        label: 'reason',
        input_type: 'text',
        items: ['we reached a funding goal', 'we benefitted from a successful support act (event etc.)', 'the three-month follow-up time has passed after the release of funds'],
        value: '',
        input_value_condition: '',
        value_2: '',
        label_2: 'action page link',
        input_id: 'reason'

      },
    ],
    step_2: [

      {
        title: 'For which fund allocation did you decide, according to the plan we put together on your action page? ',
        field_type: 'checkbox',
        label: 'method',
        input_type: 'text',
        items: [],
        value: [],
        input_value_condition: '',
        value_2: '',
        label_2: '',
        input_id: 'fund_method'

      }, {
        title: 'How was it placed:',
        field_type: 'select',
        label: 'method',
        input_type: 'text',
        items: ['in percentages', 'in dollar amount'],
        value: '',
        input_value_condition: '',
        value_2: '',
        label_2: '',
        input_id: 'fund_method'

      }, {
        title: 'Did you follow the funding plan (allocation of percentages etc.)',
        field_type: 'select-input',
        label: 'method',
        input_type: 'text',
        items: ['yes', 'no, because:'],
        value: '',
        input_value_condition: 'no, because:',
        value_2: '',
        label_2: 'reason',
        input_id: 'why?'

      }, {
        title: 'Did you reach on of the goals or the maximum goal of the campaign? ',
        field_type: 'select',
        label: 'goal',
        input_type: 'text',
        items: ['we reached the total amount ', 'we reached on of the subgoals', 'our fundraising was open end'],
        value: '',
        input_value_condition: 'no, because:',
        value_2: '',
        label_2: 'reason',
        input_id: 'why?'

      }, {
        title: 'How was your experience with the funding plan? Did it feel realistic? What would you do differently the next time? Where would you need additional support?',
        field_type: 'text-area',
        label: 'experience',
        input_type: 'text',
        items: [],
        value: '',
        input_value_condition: 'no, because:',
        value_2: '',
        label_2: 'reason',
        input_id: 'why?'

      },
    ],
    step_3: [
      {
        title: 'Date',
        field_type: 'text-field',
        input_type: 'date',

        label: 'Date',
        items: [],
        value: '',
        input_id: 'support_date'

      }, {
        title: 'Title',
        field_type: 'text-field',
        input_type: 'text',

        label: 'Title',
        items: [],
        value: '',
        input_id: 'support_title'

      }, {
        title: 'Type of event:',
        field_type: 'text-field',
        input_type: 'text',

        label: 'event',
        items: [],
        value: '',
        input_id: 'support_type'

      }, {
        title: 'Organizers:',
        field_type: 'text-area',
        input_type: 'text',

        label: 'organizers',
        items: [],
        value: '',
        input_id: 'support_organizers'

      }, {
        title: 'Event page link:',
        field_type: 'text-field',
        input_type: 'text',

        label: 'link',
        items: [],
        value: '',
        input_id: 'support_page_link'

      }, {
        title: 'Amount raised:',
        field_type: 'text-field',
        input_type: 'number',

        label: 'link',
        items: [],
        value: '',
        input_id: 'support_amount_raised'

      }, {
        title: 'Did the type of the event fit well with your philosophy?',
        field_type: 'select',
        label: 'philosophy',
        input_type: 'text',
        items: ['yes', 'no'],
        value: '',
        input_value_condition: '',
        value_2: '',
        label_2: '',
        input_id: 'philosophy_type'

      }, {
        title: 'Did team members or volunteers of yours attend the event?',
        field_type: 'select',
        label: 'attended',
        input_type: 'text',
        items: ['yes', 'no'],
        value: '',
        input_value_condition: '',
        value_2: '',
        label_2: '',
        input_id: 'attended'

      }, {
        title: 'Did you share the events invite with your community?',
        field_type: 'select',
        label: 'invites',
        input_type: 'text',
        items: ['yes', 'no'],
        value: '',
        input_value_condition: '',
        value_2: '',
        label_2: '',
        input_id: 'invites'

      }, {
        title: 'Do you think the act achieved the best possible reach?',
        field_type: 'select-input',
        label: 'reach',
        input_type: 'text',
        items: ['yes', 'no, this might be the reason:'],
        value: '',
        input_value_condition: 'no, this might be the reason:',
        value_2: '',
        label_2: 'reason',
        input_id: 'reach'

      }, {
        title: 'Would you collaborate with the same organizers again?',
        field_type: 'select-input',
        label: 'reach',
        input_type: 'text',
        items: ['yes', 'no, because:'],
        value: '',
        input_value_condition: 'no, because:',
        value_2: '',
        label_2: 'reason',
        input_id: 'collaboration'


      },
      {
        title: 'Comments, feedback & ideas to the supportive act: ',
        field_type: 'text-area',
        input_type: 'text',

        label: 'feedback',
        items: [],
        value: '',
        input_id: 'comments_feedback'

      },
    ],
    step_4: [{
      title: 'Our initiative wants to continue fundraising:',
      field_type: 'select',
      label: 'philosophy',
      input_type: 'text',
      items: ['yes', 'no'],
      value: '',
      input_value_condition: '',
      value_2: '',
      label_2: '',
      input_id: 'philosophy'

    }, {
      title: 'We want to continue with the following forms (multiple answers possible)',
      field_type: 'select-input',
      label: 'continuation',
      input_type: 'text',
      items: ['we want to continue with this action for an extended period', 'we are interested in another supportive act', 'we are interested in creating collaboration with other initiatives', 'we’d like to wrap this action up and create a new one for the next goals', 'we already have a specific new idea to share with you:'],
      value: '',
      input_value_condition: 'we already have a specific new idea to share with you:',
      value_2: '',
      label_2: 'specific idea',
      input_id: 'continuation'

    },

    {
      title: 'Our next goals and ideas, but also questions: ',
      field_type: 'text-area',
      input_type: 'text',

      label: 'goals',
      items: [],
      value: '',
      input_id: 'goals'

    }],
    step_5: [
      {
        title: 'With which ardkon acts would you describe your action’s impact (learn about the ardkon acts here: https://www.ardkon.com/ardkon/about-us )?',
        field_type: 'select',
        label: 'philosophy',
        input_type: 'text',
        items: ['1. we do no harm', '2. we live together', '3. we drink the same ', '4. we feed all ', '5. we travel slow', '6. we work for all ', '7. we share what we know ', '8. we pray as one ', '9. we rebel'],
        value: '',
        input_value_condition: '',
        value_2: '',
        label_2: '',
        input_id: 'acts'

      }, {
        title: 'Were you able to support marginalized or vulnerable groups with this action?: ',
        field_type: 'select-input',
        label: 'philosophy',
        input_type: 'text',
        items: [' unfortunately not', 'yes, which and how in particular:'],
        value: '',
        input_value_condition: 'yes, which and how in particular:',
        value_2: '',
        label_2: 'specific idea',
        input_id: 'impact_support'

      },
      {
        title: 'Give us a short statement on the impact that you are aiming for with your initiative and how the current action is performing in this regard. ',
        field_type: 'text-area',
        input_type: 'text',

        label: 'impact statement',
        items: [],
        value: '',
        input_id: 'impact_statement'

      }
    ],
    step_6: [
      {
        title: 'Do you know about ardkon’s democratized smart contracts? Whenever individual donations are being done for your action, your supportive community can vote on the fund allocation that they believe is most important to create more engagement between both sides. ',
        field_type: 'select',
        label: 'smart contracts',
        input_type: 'text',
        items: ['we are actively implementing the outcomes of it in our decision making. ', 'we know about it but don’t quite know how it works. ', 'we don’t know about it but are interested to hear more!', 'we don’t think it is a good idea or applicable for our operations.'],
        value: '',
        input_value_condition: '',
        value_2: '',
        label_2: '',
        input_id: 'smart_contracts'

      },
    ],
    step_7: [
      {
        title: 'Any other issues, feedback or questions that you want to share with us about your action, the platform and further steps? ',
        field_type: 'text-area',
        label: 'comments',
        input_type: 'text',
        items: [],
        value: '',
        input_value_condition: '',
        value_2: '',
        label_2: '',
        input_id: 'comments'

      },],
    step_8: [
      {
        title: 'receipts, contracts with freelancers or contractors ',
        field_type: 'documents',
        images_urls: [],
        input_id: 'reciepts'
      }, {
        title: 'spreadsheet over expenses & spending (in USD)',
        field_type: 'document',
        images_urls: [],
        input_id: 'expenses'
      }, {
        title: 'pictures of purchased equipment',
        field_type: 'documents',
        images_urls: [],
        input_id: 'equipment'
      }, {
        title: 'any additional material to show how you spent the donated funds',
        field_type: 'documents',
        images_urls: [],
        input_id: 'additional_material'
      }
    ],
    step_9: [
      {
        title: 'a video with your team presenting the outcome of the funds invested ',
        field_type: 'document',
        images_urls: [],
        input_id: 'team_video'
      }, {
        title: 'testimony video showing the community impact',
        field_type: 'document',
        images_urls: [],
        input_id: 'testimony_video'
      }, {
        title: 'photos, texts & other material about your current operations and how they approved',
        field_type: 'documents',
        images_urls: [],
        input_id: 'material'
      },
      {
        title: 'did you benefit from a supportive act? thank you - material (video, post etc.) for the organizers & social media ',
        field_type: 'text-area',
        label: 'benefits',
        input_type: 'text',
        items: [],
        value: '',
        input_value_condition: '',
        value_2: '',
        label_2: '',
        input_id: 'benefits'

      }
    ],

    stages: {
      step_1: false,
      step_2: false,
      step_3: false,
      step_4: false,
      step_5: false,
      step_6: false,
      step_7: false,
      step_8: false,
      step_9: false
    },

    steps_info:{
      step_current_id: 1,
      steps_content:[
        {
            step_id:1,
            step_name: 'Story',
            step_description_1: 'Behind every game, behind each training and behind each goal there is passion, dedication and commitment to the beauty of the game. As seamless the game may seem, it has been built on more than 200 years of sweat & tears. Yet behind this unique manifestation of this game are people like us that pushed it forward. They were no ordinary men & women their where the best of them all. ',
            step_description_2: 'Yet through the misery and glory of time few made to the top and few yet remained. But as any great players, Maradona & the like, legends fucking DIE. Yet as technology is growing and evolution jumped into a different dimension, we want to turn everyone of all our legends to immortal players passing, scoring and building the ever growing digital world. For us is an act to honor the greatest of the game for future generation to not just see them as card but to meet them as the legends they are. ',
            step_description_3: 'Hence a new world for the best is born to allow the wining & fame grow beyond the feet of the players to the heart and souls of the FANS the foundation of our beautiful game. Hence players will compete again in a world where they will always live in 4ever. For that some call them legends but we call them',
            step_statment: 'legents… the next generation of football history…',
        } ,
        
        {
            step_id:2,
            step_name: 'Environment',
            step_description_1: 'Legents will be built on a block based digital environment that will merge different digital services & infrastructures to create the first 3D interactive game that is built to achieve digital immortality for cards attributes, stats & history.  ',
            step_description_2: 'A fully decentralized economy and politics will be built around one stadium that will hosts training, battling and trading rooms that will determine the developments of the digital relationships between players, managers and fans that will bring the top clubs to win valuable in kind and crypto rewards.  ',
            step_description_3: 'Users will be able to start their journey unlocking bundles of NFTs that will set them up in their club building career. Clubs will be able to trade services and assets through legentcoin that will be traded publically & privately to become one the first digital & crypto foundation building the future blocks of football.  ',
            step_statment: '',

        },
        {
            step_id:3,
            step_name: 'Stadium',
            step_description_1: 'Beginning 2022, a new mission will embark from planet earth to the moon! Pioneers in the football world had joined efforts to land for the first time as a united nation of football and colonize the moon by building the first universal space stadium that will host all legents community & activities.',
            step_description_2: 'Earth fans, managers & players are invited to build up next to the stadium the first colony of football settlers that will govern the future generation that we will together inhibit the moon in the name of football. ',
            step_description_3: 'Hence the moon will be minted for the production of all the digital assets required to build a thriving digital economy on the moon & beyond, claiming stars in the name of the legents of our community.',
            step_statment: '',
        },
        {
            step_id:4,
            step_name: 'Energy',
            step_description_1: 'Before any movement energy need to be sourced, saved and transported to the facilities that will travel alongside the first builders to establish our energy source close to the moon and the sun. Hence the legentcoin will be mined on the number of solar energy in kwats that we are able to provide our community members with.  ',
            step_description_2: 'We will be building by the returns and profit raised, different solar power partnerships that will make sure that our system is fully sustainable and solar. The solar machines will feed our digital world and contribute to the acceleration of mass solar adoption. ',
            step_description_3: '',
            step_statment: '',
        },
        {
            step_id:5,
            step_name: 'Game',
            step_description_1: 'The game evolves around generation of bundles that are released to public in each evolution cycle of the game. At first 555 bundles of 18 players (3 unique, 6 rare, 9 common) will be sold at 555 usd equivalent in legentcoin. On the release of each generation a reward for the top clubs will be announced that will always have a real-life physical component as tickets to champion leagues final, top team on another generation will be invited to the world cup, dinners and meetups with players and managers and more. ',
            step_description_2: 'Each generation of clubs will increase 10 times in value in every release. Yet alone any user can start building thier own clubs outside of the bundles generation releases. Hence a market place & trading rooms will be developing to accompany the journeys of all the clubs meeting, communicating, being involved with the players and legends of the game of football. ',
            step_description_3: 'After bundles and clubs have been released and formed players can start competing against each other in stimulated matches environments that will increase the value of the squad and club winning. A fully economy and community will be build that will have many roles in building the future of the game beyond competitions. Plans and strategy will be mathematically driven football complications that will start solving with the mining of the legents technology and reach of influence. ',
            step_statment: 'The aim of the game is to bring all games to the moon in the right timing of expansion! Until then we aim to release of first generation for battle in the first season of 2022. ',
        },
        {
            step_id:6,
            step_name: 'Economy',
            step_description_1: 'The economy of legents will build on building, trading and developing nft assets while all transactions will be done through the legentcoin. User will be able to buy the following: <br><br> 1- PFP profile picture nft<br> 2- Digital space inside the stadium from brands and companies<br>   3- Digital land on the moon for clubs and players to buy and build business around <br>  4- Items & gear <br>  5- Bundles <br>  6- Coins   ',
            step_description_2: 'All competition will reward based for the top users that achieve status through matches wining. There will be fees to enter every matches and users will be rewarded power and returns from fans engagement and entry!  ',
            step_description_3: 'As for the fans they will be able to create their own fan clubs and business that they wish the support their clubs with to increase club value. ',
            step_statment: 'The bundles will hold the most value as they will always bring unique assets to each generation. ',
        },
        {
            step_id:7,
            step_name: 'Experience',
            step_description_1: 'Our legents will be build on the first fully 3D immersive block based technologies that will allow us to introduce different communities event that will bring uniqueness to the experience:  <br> <br>  1- Legents photographs & autographs with players  <br>  2- Legents Meetups & gatherings/events  <br>  3- Legents hangouts & tournaments ',
            step_description_2: 'The experience should be tailored for a 24/7 full 3d life that has to take into consideration all the details of visualizing all digital assets & personals that will be required to deliver a real life 3D verse. The Legents verse is not just a gaming but a society that moved to the moon and we want our users to build the transition with us.  ',
            step_description_3: '',
            step_statment: '',
        },
        {
            step_id:8,
            step_name: 'Operations',
            step_description_1: 'The cycle-methodology<br>When presented with a contextual need for innovation, the best use of the human and technological resources is the management of expectations and assets. ardkon created a specific cycle philosophy as a key operation system that supports asset creation and develops a striving environment that delivers strategic outcomes. Three phases of brainstorming, planning and execution result in a minimum viable solution (MVS) for initiatives or freelancers working on a task to test, sustain or scale its impact. The three phases are following a systematic management based on a sequence of 369 – 555:  <br><br>369 stands for: compassion, idealism and creative expression.<br>555 stands for: freedom, exploration and change.',
            step_description_2: 'By that a short term deliverable is being created that is managed by the amount of resources dedicated to create value at and for a certain period of time. On the contrary of organic growth, the value in this system is the result of brainstorming, planning and execution coexisting and continuing each other and yielding a tangible outcome. The cycle is a tool adopted to reduce and manage stress resulting in an increase in performance and creativity, redesigned to channel and divide focus, resulting in an increase in drive and flexibility and developed to capitalize and scale input resulting in an increase in value and return.',
            step_description_3: '1- Hourly: (assessing ideas and executing low commitment operations)<br>  a- 3 hours of brainstorming<br>  b- 5 hours of (rest, assurance, review)<br>  c- 6 hours of planning<br> d- 10 hours of (rest, assurance, review)<br>  e- 9 hours of execution<br><br>    2- Daily: (realizing value and executing middle to high commitment operations)<br><br>  a- 3 days of brainstorming<br>  b- 5 days of (rest, assurance, review)<br>  c- 6 days of planning<br>   d- 5 days of (rest, assurance, review)<br>  e- 9 days of execution<br>  f- 5 days of (rest, assurance, review)',
            step_statment: '',
        },
        {
            step_id:9,
            step_name: 'Logs/Ledgers',
            step_description_1: 'All legents related activities have to be recorded on the legents block that will insure the history of our society that is moving together. All records will be publically accessed. Yet Each user will as well have their own financial environments that will be given different types of NFTs for storage of data, utilities and access.          ',
            step_description_2: '',
            step_description_3: '',
            step_statment: '',
        }
    ],
   
    },
    users: [],
    access_messages: [],
    actions: []
  }),
  mutations: {
    SAVE_MESSAGES(state, data){
      state.access_messages = [];
        for (var i = 0; i < data.length ; i ++){
          state.access_messages.unshift(data[i]);
        }
    }, SAVE_USERS(state, data){
      state.users = [];
        for (var i = 0; i < data.length ; i ++){
          state.users.unshift(data[i]);
        }
    },
    REGISTERED(state,data){
      state.registration_info = data.data.app_info
      state.account_status = 'registered'
    },
    SET_LOCATION(state,data){
      state.location = data
    },
    NEXT_SECTION(state ,step){
      state.step_current_id = step.step_id + 1;
      
    },

    SET_FUND_ALLOCATION(state, data) {
      state.step_2[0].items = []
      for (let i = 0; i < data.length; i++) {

        state.step_2[0].items.push(data[i].fund_title);

      }


    },
    SET_ACTIONS(state, data) {
      for (let i = 0; i < data.length; i++) {

        state.actions.push(data[i]);


      }
    },
    PRE_SAVE(state, data) {
      if (data.e6 === 1) {
        state.step_1 = data.info
        state.stages.step_1 = true

      }
      else if (data.e6 === 2) {
        state.step_2 = data.info
        state.stages.step_2 = true

      }
      else if (data.e6 === 3) {
        state.step_3 = data.info
        state.stages.step_3 = true

      }
      else if (data.e6 === 4) {
        state.step_4 = data.info
        state.stages.step_4 = true

      }
      else if (data.e6 === 5) {
        state.step_5 = data.info
        state.stages.step_5 = true

      }
      else if (data.e6 === 6) {
        state.step_6 = data.info
        state.stages.step_6 = true

      }
      else if (data.e6 === 7) {
        state.step_7 = data.info
        state.stages.step_7 = true

      }
      else if (data.e6 === 8) {
        state.step_8 = data.info
        state.stages.step_8 = true

      }
      else if (data.e6 === 9) {
        state.step_9 = data.info
        state.stages.step_9 = true

      }
    },
    SAVE(state, data) {
      if (data.e6 === 1) {
        for (var i = 0; i < data.info.length; i++) {
          state.step_1.push(data.info[i])
        }
        state.stages.step_1 = true
      }
      else if (data.e6 === 2) {
        for (var h = 0; i < data.info.length; i++) {
          state.step_2.push(data.info[h])
        }
        state.stages.step_2 = true
      }
      else if (data.e6 === 3) {
        for (var g = 0; i < data.info.length; i++) {
          state.step_3.push(data.info[g])
        }
        state.stages.step_3 = true
      }
      else if (data.e6 === 4) {
        for (var f = 0; i < data.info.length; i++) {
          state.step_4.push(data.info[f])
        }
        state.stages.step_4 = true
      }
      else if (data.e6 === 5) {
        for (var e = 0; i < data.info.length; i++) {
          state.step_5.push(data.info[e])
        }
        state.stages.step_5 = true
      }
      else if (data.e6 === 6) {
        for (var d = 0; i < data.info.length; i++) {
          state.step_6.push(data.info[d])
        }
        state.stages.step_6 = true
      }
      else if (data.e6 === 7) {
        for (var c = 0; i < data.info.length; i++) {
          state.step_7.push(data.info[c])
        }
        state.stages.step_7 = true
      }
      else if (data.e6 === 8) {
        for (var b = 0; i < data.info.length; i++) {
          state.step_8.push(data.info[b])
        }
        state.stages.step_8 = true
      }
      else if (data.e6 === 9) {
        for (var a = 0; i < data.info.length; i++) {
          state.step_9.push(data.info[a])
        }
        state.stages.step_9 = true
      }
    }
  },
  getters: {


    getActiveAction: (state) => (param) => {


      return state.actions.find(action => action.action_param === param)
    },

  },
  actions
}

export default reports